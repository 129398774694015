import { SnackbarProvider } from 'notistack';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ProgressBarStyle } from './components/grafici/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import ThemeSettings from './components/settings';
import { UserContextProvider } from './contexts/UserContext';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ProgressBarStyle />

          <ScrollToTop />
          <SnackbarProvider
            autoHideDuration={2000}
            preventDuplicate
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          />
          <UserContextProvider>
            <Router />
          </UserContextProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
