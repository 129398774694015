// devo creare un timer che si aggiorna ogni secondo

import { CircularProgress, Skeleton, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/it';
import Iconify from '../Iconify';
import { useRoundContext } from '../../contexts/round-context/RoundContext';

dayjs.locale('it');
dayjs.extend(customParseFormat);

export default function TimerRound() {
  const { timeLeft, loadingRound } = useRoundContext();
  // convertire secondi in HH:mm
  const convertSeconds = (seconds) => {
    if (seconds < 0) seconds *= -1;
    const date = new Date(null);
    date.setSeconds(seconds); // specify value for SECONDS here
    const result = date.toISOString().substr(11, 8);
    return result;
  };
  if (loadingRound) return <CircularProgress />;
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Iconify icon={'bx:timer'} color="#fdfdfd" width={42} height={42} />
      <Typography
        variant="span"
        color={timeLeft < 0 ? '#FFC107' : 'primary'}
        sx={{ fontSize: '30px', fontWeight: 'bold' }}
      >
        {timeLeft ? (
          <p>
            <span>{convertSeconds(timeLeft / 1000)}</span>
          </p>
        ) : (
          <p>Gioco terminato 🔥</p>
        )}
      </Typography>
    </Stack>
  );
}
