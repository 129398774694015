import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import WithSendRequest from '../contexts/with-sendrequest';
import { useUser } from '../contexts/UserContext';

// layouts
import DashboardLayout from '../layouts/dashboard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { RoundContextProvider } from '../contexts/round-context/RoundContext';
import { NotificationContextProvider } from '../contexts/NotificationContext';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { logged, fetchingUserInfo } = useUser();
  const publicRoute = useRoutes([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      path: '*',
      element: <Navigate to={'/login'} replace />,
      index: true,
    },
  ]);

  const privateRoute = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <HomePage />, index: true },
        {
          path: '/logout',
          element: <Logout />,
        },
      ],
    },
    {
      path: '/ruolo/:ruolo',
      element: <DashboardLayout />,
      children: [
        { path: 'data-room', element: <DataRoom /> },
        {
          path: 'action-plan',
          children: [
            { path: 'forecast', element: <Forecast /> },
            { path: 'strutture', element: <Strutture /> },
            { path: 'risorse', element: <Risorse /> },
            { path: 'sourcing', element: <Sourcing /> },
            { path: 'offer', element: <Offer /> },
            { path: 'iniziative', element: <Iniziative /> },
            { path: ':tipoaction/lista-selezione', element: <ListaSelezione /> },
            { path: 'strutture/:id', element: <PaginaDettaglioStruttura /> },
            { path: 'risorse/:id', element: <PaginaDettaglioRisorsa /> },
            { path: 'sourcing/:id', element: <PaginaDettaglioSourcing /> },
            { path: 'offer/:id', element: <PaginaDettaglioOffer /> },
            { path: 'iniziative/:id', element: <PaginaDettaglioIniziative /> },
          ],
        },
        { path: 'notifications', element: <Notifications /> },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [{ path: 'financial-reports', element: <FinancialReports /> }],
    },

    { path: '*', element: <Navigate to="/" replace /> },
  ]);

  // case refresh the page, fetchingUserInfo is true. shows loading screen. then initiate useEffect with getUserInfo.
  // if (fetchingUserInfo)  return 'hi';
  if (fetchingUserInfo) return <LoadingScreen />;
  // case use is logged. has contexts(UserContext is always included in App level.) fetches round every seconds.
  if (logged) {
    return (
      <>
        <RoundContextProvider>
          <NotificationContextProvider>
            <WithSendRequest>{privateRoute}</WithSendRequest>
          </NotificationContextProvider>
        </RoundContextProvider>
      </>
    );
  }
  // case user is not logged in.
  return <>{publicRoute}</>;
}

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));

// Dashboard
const DataRoom = Loadable(lazy(() => import('../pages/DataRoom')));
const Forecast = Loadable(lazy(() => import('../pages/Forecast')));
const Strutture = Loadable(lazy(() => import('../pages/Strutture')));
const FinancialReports = Loadable(lazy(() => import('../pages/FinancialReports')));
const Risorse = Loadable(lazy(() => import('../pages/Risorse')));
const Sourcing = Loadable(lazy(() => import('../pages/Sourcing')));
const Offer = Loadable(lazy(() => import('../pages/Offer')));
const Iniziative = Loadable(lazy(() => import('../pages/Iniziative')));
const Notifications = Loadable(lazy(() => import('../pages/Notifications')));
const ListaSelezione = Loadable(lazy(() => import('../pages/ListaSelezione')));
const PaginaDettaglioStruttura = Loadable(lazy(() => import('../pages/PaginaDettaglioStruttura')));
const PaginaDettaglioRisorsa = Loadable(lazy(() => import('../pages/PaginaDettaglioRisorsa')));
const PaginaDettaglioSourcing = Loadable(lazy(() => import('../pages/PaginaDettaglioSourcing')));
const PaginaDettaglioOffer = Loadable(lazy(() => import('../pages/PaginaDettaglioOffer')));
const PaginaDettaglioIniziative = Loadable(lazy(() => import('../pages/PaginaDettaglioIniziativa')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Logout = Loadable(lazy(() => import('../pages/auth/Logout')));
