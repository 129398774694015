import dayjs from 'dayjs';
import { sendRequest } from '../../utils/send-request';
import { PATH_API } from '../../utils/enum/pathApi';

export const calculateTimeLeft = (timer, currentRound, nextRound) => {
  const adesso = new Date();
  let timeLeft = null;
  let enteredBreak = false;

  if (currentRound) {
    enteredBreak = false;
    // parse date fine round with dayjs
    const fineCurrent = dayjs(currentRound && currentRound.fine_round, 'YYYY-MM-DD HH:mm:ss');

    // const difference = new Date(adesso.getTime()+1200000) - adesso.getTime();
    const differenceCurrent = fineCurrent - adesso;
    // console.log(new Date().setTime(new Date().getTime()+ (20 * 60 * 1000)));

    timeLeft = differenceCurrent;
    // console.log('gioco in corso');
  }

  if (!currentRound && nextRound) {
    enteredBreak = true;
    // calculate time left for next round
    // console.log('in attesa di inizio round');
    const inizioNext = dayjs(nextRound && nextRound.inizio_round, 'YYYY-MM-DD HH:mm:ss');
    const differenceNext = inizioNext - adesso;
    timeLeft = -differenceNext;
  }

  if (!currentRound && !nextRound) {
    console.log('gioco finito!');
    // onResetRounds();
    clearInterval(timer);
    timeLeft = null;
  }
  return timeLeft;
};
