import React from 'react';

export const NotificationContext = React.createContext({
  notifiche: [],
  setNotifiche: () => {},
});

export const NotificationContextProvider = ({ children }) => {
  const [notifiche, setNotifiche] = React.useState([]);

  return <NotificationContext.Provider value={{ notifiche, setNotifiche }}>{children}</NotificationContext.Provider>;
};
