import React, { createContext, useEffect, useState } from 'react';
import useGame from '../../hooks/useGame';
import { useUser } from '../UserContext';
import { calculateTimeLeft } from './calculateTimeLeft';
import { useCheckCurrentAndNextRound } from './useCheckCurrentAndNextRounds';
import { sleep } from '../../utils/sleep';

export const RoundContext = createContext({
  currentRound: null,
  setCurrentRound: () => {},
  nextRound: null,
  setNextRound: () => {},
  loadingRound: true,
  setLoadingRound: () => {},
  timeLeft: null,
  setTimeLeft: () => {},
});

const useRoundStore = () => {
  const [currentRound, setCurrentRound] = useState(null);
  const [nextRound, setNextRound] = useState(null);
  const [loadingRound, setLoadingRound] = useState(true);
  // calculate time left should know the state of loadingRound to  calculate the time left(At first tim e it says 'gioco finito')
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(currentRound, nextRound));

  const { info, rounds, fetchingUserInfo, getUserInfo, session, avanzamento_sessione } = useUser();

  const { rounds2save, onInitRound, syncFinishedRounds, onResetRounds } = useGame();

  const checkCurrentAndNextRound = () => {
    const now = new Date();

    const currentRound = rounds.find((round) => {
      const start = new Date(round.inizio_round);
      const end = new Date(round.fine_round);
      return start < now && end > now;
    });

    // found next round
    const nextRound = rounds.find((round) => {
      const start = new Date(round.inizio_round);
      return start > now;
    });

    if (new Date(currentRound?.fine_round) - 1000 < now) {
      sleep(1000).then(() => {
        getUserInfo();
      });
    }
    return {
      currentRound,
      nextRound,
    };
  };

  useEffect(() => {
    // when user info has been fetched from the server. it is ready to check for rounds
    if (fetchingUserInfo !== false) return;
    handleRounds();
    const timer = setInterval(() => {
      const _timeLeft = handleRounds(timer);
      if (_timeLeft < 1000) {
        sleep(1000).then((_) => {
          getUserInfo();
        });
      }
      if (loadingRound) {
        setLoadingRound(false);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [fetchingUserInfo]);

  function handleRounds(timer) {
    const { currentRound, nextRound } = checkCurrentAndNextRound();
    setCurrentRound(currentRound);
    setNextRound(nextRound);
    const _timeLeft = calculateTimeLeft(timer, currentRound, nextRound);
    setTimeLeft(_timeLeft);
    return _timeLeft;
  }

  const context = {
    currentRound,
    setCurrentRound,
    nextRound,
    setNextRound,
    loadingRound,
    timeLeft,
    setTimeLeft,
    setLoadingRound,
  };
  return context;
};

export const RoundContextProvider = ({ children }) => {
  return <RoundContext.Provider value={useRoundStore()}>{children}</RoundContext.Provider>;
};

export const useRoundContext = () => React.useContext(RoundContext);
