import React, { useEffect, useState } from 'react';
import { de } from 'date-fns/locale';
import { useNavigate } from 'react-router';
import { sendRequest } from '../utils/send-request';
import { PATH_API } from '../utils/enum/pathApi';

const defaultUserInfo = {
  info: {},
  game: {},
  session: {},
  rounds: [],
  logged: false,
  authenticated: false,
  accessi: [],
  accessiArrayStr: [],
  setUserInfo: () => {},
  authUser: () => {},
  logoutUser: () => {},
  getUserInfo: () => {},
  fetchingUserInfo: true,
  avanzamento_sessione__utente: {},
  setAvanzamento_sessione__utente: () => {},
  setOutput: (output) => {},
};
export const UserContext = React.createContext(defaultUserInfo);

function useUserStore() {
  // ! todo: relocate the context in to the user context.(separate from routes.)
  const [userInfo, setUserInfo] = useState(defaultUserInfo);
  const authUser = () => setUserInfo({ ...userInfo, authenticated: true });
  const navigate = useNavigate();

  const logoutUser = async () => {
    setUserInfo({ ...defaultUserInfo, fetchingUserInfo: false });
    await sendRequest.get(PATH_API.auth.logout);
    navigate('/login');
  };

  const getUserInfo = async () => {
    try {
      const rawRes = await sendRequest.get('/utente/info');
      setUserInfo({
        ...rawRes,
        logged: true,
        info: { ...rawRes, role: rawRes.funzione.nome },
        authenticated: true,
        fetchingUserInfo: false,
      });
    } catch (err) {
      console.log(err);
      setUserInfo({ ...defaultUserInfo, fetchingUserInfo: false });
    }
  };

  useEffect(() => {
    handleInitiallyGetInfo();
  }, []);

  // the function runs only once at the beginning of the app.
  const handleInitiallyGetInfo = async () => {
    // case user is not logged in check cookie first.
    const res = await sendRequest.get('/utente/check-cookie').catch(console.error); // should not throw error from server.
    // case checkCookie is ok. get full user info.
    if (res?.verified) {
      getUserInfo();
      return;
    }
    // case checkCookie is not ok. set fetchingUserInfo to false.
    setUserInfo({ ...defaultUserInfo, fetchingUserInfo: false });
  };
  const setAvanzamento_sessione__utente = (ASU) => {
    setUserInfo((prev) => ({
      ...prev,
      avanzamento_sessione__utente: ASU,
    }));
  };
  const setOutput = (output) => {
    setUserInfo((prev) => ({
      ...prev,
      avanzamento_sessione__utente: {
        ...prev.avanzamento_sessione__utente,
        output,
      },
    }));
  };
  /**
   *
   */
  const context = {
    ...userInfo,
    accessiArrayStr: userInfo.accessi?.map((accesso) => accesso.nome),
    setUserInfo,
    logoutUser,
    getUserInfo,
    setAvanzamento_sessione__utente,
    setOutput,
    authUser,
  };
  return context;
}

export const useUser = () => React.useContext(UserContext);

export const UserContextProvider = ({ children }) => {
  return <UserContext.Provider value={useUserStore()}>{children}</UserContext.Provider>;
};
