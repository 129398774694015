// components
// import SvgIconStyle from '../../../components/SvgIconStyle';

import { Badge } from '@mui/material';
// ----------------------------------------------------------------------

// const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// const ICONS = {
//   user: getIcon('ic_user'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
// };

const navConfig = [
  // RUOLI
  // ----------------------------------------------------------------------
  {
    subheader: 'RUOLI',
    items: [
      {
        title: 'HR',
        path: 'hr',
        children: [
          { title: 'Data Room', path: '/ruolo/:ruolo/data-room' },
          {
            title: 'Action Plan',
            path: 'action-plan',
            children: [
              { title: 'Forecast', path: '/ruolo/:ruolo/action-plan/forecast' },
              { title: 'Strutture', path: '/ruolo/:ruolo/action-plan/strutture' },
              { title: 'Risorse', path: '/ruolo/:ruolo/action-plan/risorse' },
              { title: 'Sourcing', path: '/ruolo/:ruolo/action-plan/sourcing' },
              { title: 'Offer', path: '/ruolo/:ruolo/action-plan/offer' },
              { title: 'Iniziative', path: '/ruolo/:ruolo/action-plan/iniziative' },
            ],
          },
          {
            title: 'Notifications',
            type: 'notif_count',
            path: '/ruolo/:ruolo/notifications',
          },
        ],
      },
    ],
  },

  // REPORT
  // ----------------------------------------------------------------------
  {
    subheader: 'REPORT',
    items: [
      {
        title: 'FINANCIAL REPORTS',
        path: '/financial-reports',
        // icon:.user,
        // children: [
        //   { title: 'Four', path: '/financial-reports/' },
        //   { title: 'Five', path: '/financial-reports' },
        //   { title: 'Six', path: '/financial-reports' },
        // ],
      },
    ],
  },
];

export default navConfig;
