import PropTypes from 'prop-types';
import { useContext, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Link, Typography, Grid } from '@mui/material';
// hooks
import dayjs from 'dayjs';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import AccountPopover from './account-popover/AccountPopover';
import TimerRound from '../../../components/timer/TimerRound';
import { UserContext, useUser } from '../../../contexts/UserContext';
import { RoundContext } from '../../../contexts/round-context/RoundContext';

import useGame from '../../../hooks/useGame';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  // get user info from context
  const { currentRound, nextRound } = useContext(RoundContext);
  const { fetchingUserInfo } = useUser();
  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {/* Ora e Round */}
        <Box sx={{ flexGrow: 2 }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3}>
              <TimerRound />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="p" sx={{ fontSize: '23px', fontWeight: 'bold' }}>
                {currentRound && 'Round in corso: '} {!currentRound && nextRound && 'Prossimo round: '}
                <Typography
                  variant="span"
                  color="primary"
                  sx={{
                    fontSize: '25px',
                    fontWeight: 'bold',
                  }}
                >
                  {!currentRound && nextRound ? `${nextRound.round.nome} - ${nextRound.round.descrizione}` : ''}
                  {currentRound && `${currentRound.round.nome} - ${currentRound.round.descrizione}`}{' '}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 3 }}>
          <Link underline="none" variant="subtitle" color="white" component={RouterLink} to="/learning-room">
            Learning Room
          </Link>
          <Link underline="none" variant="subtitle" color="white" component={RouterLink} to="/my-room">
            My Room
          </Link>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
