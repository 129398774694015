import { FOOTER } from '../../../config';
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function Footer() {

    const isDesktop = useResponsive('up', 'lg');

    return <footer
        style={{
            height: FOOTER.HEIGHT,
            backgroundColor: '#58D0D8',
            color: "white",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            position: 'absolute',
            bottom: 0,
            left: isDesktop ? '279px' : 0,
            width: isDesktop ? 'calc(100% - 279px)' : '100%',
        }}
    >
        Play4Job
    </footer>
}