import { enqueueSnackbar } from 'notistack';

let isRefreshing = false;
let failedQueue = [];

export const sendRequest = {
  get: (url, data = {}, isRetry = false) => {
    return fetchRequest('GET', url, data, isRetry);
  },
  post: (url, data = {}, isRetry = false) => {
    return fetchRequest('POST', url, data, isRetry);
  },
  patch: (url, data = {}, isRetry = false) => {
    return fetchRequest('PATCH', url, data, isRetry);
  },
  delete: (url, data = {}, isRetry = false) => {
    return fetchRequest('DELETE', url, data, isRetry);
  },
  refreshTokenHendler: null,
};

function fetchRequest(method, url, data, isRetry) {
  let completeUrl = process.env.REACT_APP_SERVER_URL + url;
  let dataCopy = structuredClone(data);

  let params = null;
  if (method === 'GET') {
    params = dataCopy.params;
    dataCopy = {};
  }

  if (method === 'GET' && params && Object.keys(params).length > 0) {
    completeUrl = `${completeUrl}?${buildParams(params)}`;
  }

  const options = {
    method: method,
    cache: 'no-store',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };

  if (['POST', 'PATCH'].includes(method) && Object.keys(dataCopy).length > 0) {
    options.headers = {
      ...options.headers,
      'Content-Type': 'application/json',
    };
  }

  if (method === 'POST' && dataCopy instanceof File) {
    const formData = new FormData();
    formData.append('file', dataCopy);
    options.body = formData;
  } else if (Object.keys(dataCopy).length > 0) {
    options.body = JSON.stringify(dataCopy);
  }

  return fetch(completeUrl, options)
    .then((response) => {
      if (response.status === 401 && !isRetry && sendRequest.refreshTokenHendler) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(() => {
              const method = options.method.toLowerCase();
              return sendRequest[method](url, data, true);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        isRefreshing = true;

        return new Promise((resolve, reject) => {
          const method = options.method.toLowerCase();
          sendRequest
            .refreshTokenHendler(url, method, data)
            .then((resp) => {
              processQueue(null);
              resolve(resp);
            })
            .catch((err) => {
              processQueue(err);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }

      if (response.status === 403) {
        if (!['/utente/info', '/login', '/login/refresh'].includes(url)) {
          enqueueSnackbar('Non hai i permessi per accedere a questa risorsa', {
            variant: 'error',
          });
        }
        return Promise.reject(response);
      }

      if (response.status === 404) {
        enqueueSnackbar('404 Url not found', {
          variant: 'error',
        });
        return Promise.reject(response);
      }

      if (response.status === 400) {
        response.json().then((resData) => {
          enqueueSnackbar(resData.message, {
            variant: 'error',
          });
        });
        return Promise.reject(response);
      }

      if (response.status !== 200) {
        enqueueSnackbar(`Errore ${response.status}`, {
          variant: 'error',
        });
        return Promise.reject(response);
      }

      return response.json();
    })
    .catch((error) => {
      if (error.status === 500) {
        enqueueSnackbar("Errore durante la comunicazione col server, si prega di contattare l'assistenza", {
          variant: 'error',
        });
      }

      return Promise.reject(error);
    });
}

const processQueue = (error) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

function buildParams(data) {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(key, value.toString()));
    } else {
      params.append(key, value.toString());
    }
  });

  return params.toString();
}
