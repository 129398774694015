import { MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { useUser } from '../../../../contexts/UserContext';

export const LogoutTile = () => {
  const { logoutUser } = useUser();

  return (
    <MenuItem onClick={logoutUser} sx={{ m: 1 }}>
      Logout
    </MenuItem>
  );
};
