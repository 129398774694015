import { useMemo } from 'react';
import { sendRequest } from '../utils/send-request';
import { useUser } from './UserContext';

const WithSendRequest = (props) => {
  const { setUserInfo, getUserInfo } = useUser();

  const { children } = props;

  useMemo(() => {
    // Function that will be called to refresh authorization
    const refreshAuthLogic = (url, method, data) =>
      sendRequest
        .post('/login/refresh')
        .then((response) => {
          // recupero nuovamente le info dell'utente -> che mi segna l'utente come loggato
          if (url !== '/utente/info') {
            getUserInfo();
          }

          // let method = options.method.toLowerCase();
          return Promise.resolve(sendRequest[method](url, data, true));
        })
        .catch((error) => {
          setUserInfo({
            logged: false,
            info: {},
          });

          return Promise.reject(error);
        });

    sendRequest.refreshTokenHendler = refreshAuthLogic;

    getUserInfo();
  }, []);

  return children;
};

export default WithSendRequest;
