const USER_ROOT_PATH = '/utente';
const ROOT_PATHS = {
  auth: '',
  actionPlans: `${USER_ROOT_PATH}/action-plans`,
  avanzamentoSessioneUtente: `${USER_ROOT_PATH}/avanzamento_sessione__utente`,
};
const EXECUTIONS = `${ROOT_PATHS.actionPlans}/executions`;

const ACTION_PLAN_TABLES = {
  RISORSA: 'risorsa',
  STRUTTURA: 'struttura',
  SOURCING: 'sourcing',
  DETTAGLIO_SOURCING: 'dettaglio_sourcing',
  OFFERTA: 'offerta',
  INIZIATIVA: 'iniziativa',
  INIZIATIVA_OPZIONE: 'iniziativa_opzione',
  FORECAST: 'forecast',
};

const actionPlans = Object.values(ACTION_PLAN_TABLES);

export const { RISORSA, STRUTTURA, SOURCING, OFFERTA, INIZIATIVA, DETTAGLIO_SOURCING, INIZIATIVA_OPZIONE, FORECAST } =
  ACTION_PLAN_TABLES;

const OFFERTA_ENDPOINTS = {
  root: `${ROOT_PATHS.actionPlans}/${OFFERTA}}`,
  withGameIdQuery: (gameId) => {
    return `${ROOT_PATHS.actionPlans}/${OFFERTA}?gameId=${DEV_FIX_GAME_ID || gameId}`;
  },
  byId: (id) => `${ROOT_PATHS.actionPlans}/${OFFERTA}/${id}`,
  executions: {
    root: `${EXECUTIONS}/${OFFERTA}`,
    byId: (id) => `${EXECUTIONS}/${OFFERTA}/${id}`,
  },
};

/**
 * @description endpoints: IMPORTANT NOTE. When user take some action it calls /action-plans/$name-of-the-table/executions so path.actionPlans[$table].executions.[root/byId]. Either take an action or delete the action from output thus list of selection.
 */
export const PATH_API = {
  auth: {
    login: `${ROOT_PATHS.auth}/login`,
    logout: `${ROOT_PATHS.auth}${USER_ROOT_PATH}/logout`,
  },
  'end-round': {
    root: `${USER_ROOT_PATH}/end-round`,
  },
  financialReports: {
    root: `${USER_ROOT_PATH}/financial-reports`,
  },
  dataRoom: {
    root: `${USER_ROOT_PATH}/data-room`,
  },
  home: {
    root: `${USER_ROOT_PATH}/home`,
  },
  actionPlans: {
    selections: {
      root: `${ROOT_PATHS.actionPlans}/selections`,
      team: {
        root: `${ROOT_PATHS.actionPlans}/team`,
        ...actionPlans.reduce((acc, table) => {
          acc[table] = `${ROOT_PATHS.actionPlans}/team/${table}`;
          return acc;
        }, {}),
        byId: (table, id) => `${ROOT_PATHS.actionPlans}/team/${table}/${id}`,
      },
    },
    risorsa: {
      root: `${ROOT_PATHS.actionPlans}/${RISORSA}/team`,
      withGameIdQuery: (gameId) => `${ROOT_PATHS.actionPlans}/${RISORSA}?gameId=${DEV_FIX_GAME_ID || gameId}`,
      byId: (id) => `${ROOT_PATHS.actionPlans}/${RISORSA}/${id}`,
      executions: {
        root: `${EXECUTIONS}/${RISORSA}`,
        byId: (id) => `${EXECUTIONS}/${RISORSA}/${id}`,
      },
    },
    forecast: {
      root: `${ROOT_PATHS.actionPlans}/${FORECAST}`,
      withGameIdQuery: (gameId) => `${ROOT_PATHS.actionPlans}/${FORECAST}?gameId=${DEV_FIX_GAME_ID || gameId}`,
      byId: (id) => `${ROOT_PATHS.actionPlans}/${FORECAST}/${id}`,
      executions: {
        root: `${EXECUTIONS}/${FORECAST}`,
        byId: (id) => `${EXECUTIONS}/${FORECAST}/${id}`,
      },
    },
    struttura: {
      root: `${ROOT_PATHS.actionPlans}/${STRUTTURA}`,
      withGameIdQuery: (gameId) => `${ROOT_PATHS.actionPlans}/${STRUTTURA}?gameId=${DEV_FIX_GAME_ID || gameId}`,
      byId: (id) => `${ROOT_PATHS.actionPlans}/${STRUTTURA}/${id}`,
      executions: {
        root: `${EXECUTIONS}/${STRUTTURA}`,
        byId: (id) => `${EXECUTIONS}/${STRUTTURA}/${id}`,
      },
    },
    sourcing: {
      root: `${ROOT_PATHS.actionPlans}/${SOURCING}`,
      withGameIdQuery: (gameId) => `${ROOT_PATHS.actionPlans}/${SOURCING}?gameId=${DEV_FIX_GAME_ID || gameId}`,
      byId: (id) => `${ROOT_PATHS.actionPlans}/${SOURCING}/${id}`,
      executions: {
        root: `${EXECUTIONS}/${DETTAGLIO_SOURCING}`,
        byId: (id) => `${EXECUTIONS}/${DETTAGLIO_SOURCING}/${id}`,
      },
    },
    dettaglio_sourcing: {
      // root: `${ROOT_PATHS.actionPlans}/${SOURCING}}`,
      // withGameIdQuery: (gameId) => `${ROOT_PATHS.actionPlans}/${SOURCING}?gameId=${DEV_FIX_GAME_ID || gameId}`,
      // byId: (id) => `${ROOT_PATHS.actionPlans}/${SOURCING}/${id}`,
      executions: {
        root: `${EXECUTIONS}/${DETTAGLIO_SOURCING}`,
        byId: (id) => `${EXECUTIONS}/${DETTAGLIO_SOURCING}/${id}`,
      },
    },

    // path mismatch in sevral pages defined the endpoints as object not to repeat the same path
    offerta: OFFERTA_ENDPOINTS,
    offer: OFFERTA_ENDPOINTS,

    iniziativa: {
      root: `${ROOT_PATHS.actionPlans}/${INIZIATIVA}}`,
      withGameIdQuery: (gameId) => {
        return `${ROOT_PATHS.actionPlans}/${INIZIATIVA}?gameId=${DEV_FIX_GAME_ID || gameId}`;
      },
      byId: (id) => `${ROOT_PATHS.actionPlans}/${INIZIATIVA}/${id}`,
      executions: {
        root: `${EXECUTIONS}/${INIZIATIVA}`,
        byId: (id) => `${EXECUTIONS}/${INIZIATIVA}/${id}`,
      },
    },
    iniziativa_opzione: {
      root: `${ROOT_PATHS.actionPlans}/${INIZIATIVA_OPZIONE}}`,
      withGameIdQuery: (gameId) => {
        return `${ROOT_PATHS.actionPlans}/${INIZIATIVA_OPZIONE}?gameId=${DEV_FIX_GAME_ID || gameId}`;
      },
      byId: (id) => `${ROOT_PATHS.actionPlans}/${INIZIATIVA_OPZIONE}/${id}`,
      executions: {
        root: `${EXECUTIONS}/${INIZIATIVA_OPZIONE}`,
        byId: (id) => `${EXECUTIONS}/${INIZIATIVA_OPZIONE}/${id}`,
      },
    },
  },
};
// ID OF THE GAME TO BE USED WHEN DB IS CONSTANTLY CHANGING AND DROPPING DATA. THE GAME IS AND OTHER RELATED DATA WILL BE FIXED.
export const DEV_FIX_GAME_ID = null;
