import PropTypes from 'prop-types';
import { useEffect, createContext } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
import { sendRequest } from '../utils/send-request';

// ----------------------------------------------------------------------

// voglio creare un contesto che mi permetta di accedere ai round del gioco completati per ogni utente per ogni gioco per poi sincronizzarli con il server
const initialState = {
  // rounds
  rounds2save: [],
  rounds2saveSynced: [],
  onInitRound: () => {},
};

const GameContext = createContext(initialState);

// ----------------------------------------------------------------------

GameContext.propTypes = {
  children: PropTypes.node,
};

function GameProvider({ children }) {
  const [rounds2save, setRounds2save] = useLocalStorage('rounds2save', []);
  const [rounds2saveSynced, setRounds2saveSynced] = useLocalStorage('rounds2saveSynced', []);

  const onInitRound = (round, idUser) => {
    // console.log('onFinishRound', round);
    // se il round è già presente non lo aggiungo
    if (rounds2save.find((r) => r.id === round.id)) {
      return;
    }
    round.id_user = idUser;
    round.output = 'bla bla bla';
    setRounds2save([...rounds2save, round]);
  };

  // sync rounds with server with a POST request
  const syncFinishedRounds = async () => {
    // console.log('syncFinishedRounds');
    const roundsToSync = rounds2save;
    if (roundsToSync.length === 0) {
      return;
    }
    const data = await sendRequest.post('/rounds/sync', roundsToSync);
    if (data.success) {
      // console.log('syncFinishedRounds success');
      const syncedRounds = rounds2save.map((round) => {
        if (round.synced) {
          return round;
        }
        return {
          ...round,
          synced: true,
        };
      });

      setRounds2saveSynced([...rounds2saveSynced, ...syncedRounds]);
      setRounds2save([]);
    }
  };

  // Mode
  const onResetRounds = () => {
    setRounds2save([]);
  };

  return (
    <GameContext.Provider
      value={{
        rounds2save,

        // Mode
        onInitRound,
        onResetRounds,
        syncFinishedRounds,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}

export { GameProvider, GameContext };
